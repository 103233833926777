@font-face {
  font-family: 'GT America';
  src: url('/assets/fonts/GTAmerica-Regular.eot');
  src: url('/assets/fonts/GTAmerica-Regular.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/GTAmerica-Regular.woff2') format('woff2'),
      url('/assets/fonts/GTAmerica-Regular.woff') format('woff'),
      url('/assets/fonts/GTAmerica-Regular.ttf') format('truetype'),
      url('/assets/fonts/GTAmerica-Regular.svg#GTAmerica-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT America';
  src: url('/assets/fonts/GTAmerica-Bold.eot');
  src: url('/assets/fonts/GTAmerica-Bold.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/GTAmerica-Bold.woff2') format('woff2'),
      url('/assets/fonts/GTAmerica-Bold.woff') format('woff'),
      url('/assets/fonts/GTAmerica-Bold.ttf') format('truetype'),
      url('/assets/fonts/GTAmerica-Bold.svg#GTAmerica-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}



:root {
  // font family
  --font-primary: GT America, sans-serif;
  --font-secondary: GT America, sans-serif;

  // font size
  --text-base-size: 1.4em; // body font-size
  --text-scale-ratio: 1.2; // multiplier used to generate the type scale values 👇
  
  // line-height
  --body-line-height: 1.4;
  --heading-line-height: 1.4;
  
  // capital letters - used in combo with the lhCrop mixin
  --font-primary-capital-letter: 1;
  --font-secondary-capital-letter: 1;

  // unit - don't modify unless you want to change the typography unit (e.g., from Em to Rem units)
  --text-unit: 1em; // if not Em units → --text-unit: var(--text-base-size);
}

:root, * {
  // type scale
  --text-xs: calc((var(--text-unit) / var(--text-scale-ratio)) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));
  --text-xxxxl: calc(var(--text-xxxl) * var(--text-scale-ratio));
}

@supports(--css: variables) {
  @include breakpoint(md) {
    :root {
      --text-base-size: 1.1em;
      --text-scale-ratio: 1.25;
    }
  }
}

body {
  font-family: var(--font-secondary);
  font-weight: 500;
}

h1, h2, h3, h4 {
  font-family: var(--font-primary);
  font-weight: 700;

  &.title--bordered {
    display: inline;
    background: linear-gradient(0deg,transparent 0,transparent .7rem, var(--color-accent-light) 0,var(--color-accent-light) 1.5rem,transparent 0);
  }
}

// font family
.font-primary { font-family: var(--font-primary); }
.font-secondary { font-family: var(--font-secondary); }