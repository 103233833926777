/* -------------------------------- 

File#: _2_features-v3
Title: Features v3
Descr: A section containing a list of features
Usage: codyhouse.co/license

-------------------------------- */

.features-v3__text {
  padding-top: var(--space-xxl);
  padding-bottom: calc(var(--space-xl) * 2);
  background-color: var(--color-contrast-lower);
}

.features-v3__cards {
  margin-top: calc(var(--space-xl) * -1);

  @media screen and (max-width: 768px) {
	margin-top: 0;
	}
}

/* -------------------------------- 

File#: _1_feature
Title: Feature
Descr: Content group containing text + media (img, video, iframe, inline SVG)
Usage: codyhouse.co/license

-------------------------------- */

.feature {

	.container {
		position: relative;


		&:before {
			content: '';
			position: absolute;
			width: 70vw;
			height: 80%;
			background-size: 600px auto;
			background: #ffffff url('/assets/img/watermerk.svg') no-repeat left bottom;
			z-index: -1;
			//left: -100px;
			top: -100px;
			@media screen and (max-width: 768px) {
				display: none;
			}
		
		}

		&.no--btn {
			&:before {
				content: '';
				position: absolute;
				width: 70vw;
				height: 100%;
				background-size: 600px auto;
				background: #f4f4f4 url('/assets/img/watermerk.svg') no-repeat left bottom;
				z-index: -1;
				//left: -100px;
				top: -100px;

				@media screen and (max-width: 768px) {
					display: none;
				}
			}
		}
	}
}

/* -------------------------------- 

File#: _1_feature-v3
Title: Feature v3
Descr: Content group containing text + media element that outsets its container at a specific viewport size
Usage: codyhouse.co/license

-------------------------------- */

.feature-v3 {}

@each $mq in 'sm' 'md' 'lg' { // define the feature-v3--media-outset@{mq} style at different media queries
  .feature-v3--media-outset\@#{$mq} {
    overflow: hidden;
  }

  @include breakpoint(#{$mq}) {
    .feature-v3--media-outset\@#{$mq} .feature-v3__grid {
      align-items: stretch;
    }

    .feature-v3--media-outset\@#{$mq} .feature-v3__media {
      position: relative;
      height: 100%;

      > * {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        max-width: none;
        width: auto;
      }
    }
  }

  // --invert -> invert order of feature items at specific breakpoints
  @include breakpoint(#{$mq}) {
    .feature-v3--media-outset\@#{$mq}.feature-v3--invert\@#{$mq} .feature-v3__grid > *:last-child {
      order: -1;
      
      .feature-v3__media > * {
        left: auto;
        right: 0
      }
    }
  }
}

/* -------------------------------- 

File#: _1_feature-v4
Title: Feature v4
Descr: Content group containing media element + text that outsets its container at a specific breakpoint
Usage: codyhouse.co/license

-------------------------------- */

.feature-v4 {
  --feature-text-offset: 65%; // if percentage = higher -> overlapping text takes more space
  position: relative;
  z-index: 1;
}

@each $breakpoint, $value in $breakpoints {
  @include breakpoint(#{$breakpoint}) {
    .feature-v4__text-offset\@#{$breakpoint} {
      width: calc(100% + var(--feature-text-offset));
    }
  
    .feature-v4--invert\@#{$breakpoint} {
      .feature-v4__grid > *:last-child {
        order: -1;
      }
  
      .feature-v4__text-offset\@#{$breakpoint} {
        margin-left: calc(var(--feature-text-offset) * -1);
      }
    }
  }
}

.icon__container {
  background: var(--color-accent-light);
  width: 60px;
  height: 60px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;


  img {
    display: block;
    margin: auto;
    width: 32px;
  }
}